var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.user &&
      (_vm.user.isAdmin || _vm.user.isManagingAdmin || _vm.user.isProvider) &&
      _vm.$can({ key: 'clients', expectedPermission: 'edit' })
    )?_c('button',{staticClass:"btn btn-outline-blue mb-3",on:{"click":_vm.addNewService}},[_c('icon',{attrs:{"type":"plus"}}),_vm._v(" Add Service ")],1):_vm._e(),(_vm.isLoading || _vm.gettingData)?_c('alert',{staticClass:"my-4"}):_vm._e(),(
      !_vm.isLoading &&
      _vm.patient != null &&
      _vm.displayedServices.length == 0 &&
      !_vm.gettingData
    )?_c('alert',{staticClass:"my-4",attrs:{"hideLoader":true}},[_vm._v(" No Services Attached")]):_vm._e(),(
      _vm.patient != null &&
      _vm.displayedServices.length > 0 &&
      !_vm.isLoading &&
      !_vm.gettingData
    )?_c('div',{staticClass:"card table-container border-0"},[_c('table',{staticClass:"table table-striped mb-0"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Status")]),(
              _vm.user &&
              (_vm.user.isAdmin || _vm.user.isManagingAdmin || _vm.user.isProvider) &&
              _vm.$can({ key: 'clients', expectedPermission: 'edit' })
            )?_c('th',[_vm._v(" detach ")]):_vm._e(),_c('th',{staticClass:"desktop-only"})])]),_c('tbody',_vm._l((_vm.displayedServices),function(service){return _c('tr',{key:service.id},[_c('td',[_vm._v(_vm._s(service.name))]),_c('td',{class:service.status == 'ACTIVE' ? 'text-success' : 'text-danger'},[_vm._v(" "+_vm._s(service.status)+" ")]),(
              _vm.user &&
              (_vm.user.isAdmin || _vm.user.isManagingAdmin || _vm.user.isProvider) &&
              _vm.$can({ key: 'clients', expectedPermission: 'edit' })
            )?_c('td',[_c('span',{staticClass:"ml-2 text-danger pointer-cursor"},[(!_vm.isSaving)?_c('i',{staticClass:"fas fa-unlink",on:{"click":function($event){return _vm.detachSelectedService($event, service.id)}}}):_vm._e(),(_vm.isSaving)?_c('i',{staticClass:"fa fa-spin fa-circle-notch"}):_vm._e()])]):_vm._e(),_c('td',{staticClass:"desktop-only"})])}),0)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }