<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="addNewService"
      v-if="
        user &&
        (user.isAdmin || user.isManagingAdmin || user.isProvider) &&
        $can({ key: 'clients', expectedPermission: 'edit' })
      "
    >
      <icon type="plus" /> Add Service
    </button>

    <alert v-if="isLoading || gettingData" class="my-4" />
    <alert
      :hideLoader="true"
      class="my-4"
      v-if="
        !isLoading &&
        patient != null &&
        displayedServices.length == 0 &&
        !gettingData
      "
    >
      No Services Attached</alert
    >
    <div
      class="card table-container border-0"
      v-if="
        patient != null &&
        displayedServices.length > 0 &&
        !isLoading &&
        !gettingData
      "
    >
      <table class="table table-striped mb-0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th
              v-if="
                user &&
                (user.isAdmin || user.isManagingAdmin || user.isProvider) &&
                $can({ key: 'clients', expectedPermission: 'edit' })
              "
            >
              detach
            </th>
            <th class="desktop-only"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="service in displayedServices" :key="service.id">
            <td>{{ service.name }}</td>
            <td
              :class="
                service.status == 'ACTIVE' ? 'text-success' : 'text-danger'
              "
            >
              {{ service.status }}
            </td>

            <td
              v-if="
                user &&
                (user.isAdmin || user.isManagingAdmin || user.isProvider) &&
                $can({ key: 'clients', expectedPermission: 'edit' })
              "
            >
              <span class="ml-2 text-danger pointer-cursor"
                ><i
                  v-if="!isSaving"
                  class="fas fa-unlink"
                  @click="detachSelectedService($event, service.id)"
                ></i>
                <i class="fa fa-spin fa-circle-notch" v-if="isSaving"></i>
              </span>
            </td>
            <td class="desktop-only"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "patient-services",
  data() {
    return {
      patientId: this.$route.params.id,
      isSaving: false,
      gettingData: true,
      clientServices: [],
    };
  },
  mounted() {
    if (!this.services.length) {
      this.getParentServicesNames();
    }
    this.getPatientServices(this.patient.user_id).then((data) => {
      this.clientServices = data;
      this.gettingData = false;
    });
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
      services: (state) => state.services.parentServicesBasic || [],
      isLoading: (state) => state.services.isLoading,
    }),
    displayedServices: function () {
      if (this.services && this.services.length) {
        return this.services.filter((serv) =>
          this.clientServices.includes(serv.id)
        );
      }
      return [];
    },
  },

  methods: {
    ...mapActions({
      detach: "services/detachServiceFromUser",
      getParentServicesNames: "services/getParentServicesNames",
      getPatientServices: "services/getClientServices",
    }),
    addNewService: function () {
      this.$router.push({
        name: "patients.services.create",
        params: { id: this.patientId },
        query: { src: "services" },
      });
    },
    detachSelectedService: function ($event, id) {
      $event.preventDefault();
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, detach it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.isSaving = true;
            vm.detach({ user_id: this.patient.user_id, service_id: id }).then(
              (detach) => {
                vm.isSaving = false;
                if (detach) {
                  vm.gettingData = true;
                  vm.getPatientServices(vm.patient.user_id).then((data) => {
                    vm.clientServices = data;
                    vm.gettingData = false;
                    vm.$forceUpdate();
                  });
                } else {
                  swalWithBootstrapButtons.fire(
                    "Error!",
                    "Something went wrong...",
                    "error"
                  );
                }
              }
            );
          }
        });
    },
  },
};
</script>
